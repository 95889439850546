
const digest = '988c405706164e88ce74e75ac4e1dfcf5283c9e062f2f079764f4d8c2bbf5c82';
const css = `._CalendarTableData_10av7_1 {
  display: table-cell;
  padding-top: 6px;
}

.__Button_10av7_6 {
  box-sizing: border-box;
  appearance: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 0 4px 8px;
  background: transparent;
  font: inherit;
  text-align: inherit;
  color: inherit;
  border-radius: 6px;
}

.__Button_10av7_6:enabled {
  cursor: pointer;
}

.__Button_10av7_6:enabled:hover {
  background: var(--color-black-alpha-100);
}

.__ButtonText1_10av7_31 {
  box-sizing: border-box;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  margin-inline: auto;
  border: 1px solid transparent;
  border-radius: 100%;
  color: var(--color-black);
  font-weight: 600;
}

._CalendarTableData_10av7_1.-sunday .__ButtonText1_10av7_31 {
  color: var(--color-red-600);
}

._CalendarTableData_10av7_1.-saturday .__ButtonText1_10av7_31 {
  color: var(--color-cyan-800);
}

._CalendarTableData_10av7_1.-today .__ButtonText1_10av7_31 {
  border-color: var(--color-gray-500);
}

._CalendarTableData_10av7_1[aria-selected='true'] .__ButtonText1_10av7_31 {
  color: var(--color-white);
  background-color: var(--selected-date-bg-color);
}

.__TableStatusText_10av7_61 {
  display: grid;
  place-items: center;
  width: 40px;
  height: 20px;
  margin-inline: auto;
  color: var(--color-blue-500);
}

.__TableStatusText_10av7_61.-outOfStock {
  color: var(--color-gray-300);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"CalendarTableData":"_CalendarTableData_10av7_1","calendarTableData":"_CalendarTableData_10av7_1","_Button":"__Button_10av7_6","button":"__Button_10av7_6","_ButtonText1":"__ButtonText1_10av7_31","buttonText1":"__ButtonText1_10av7_31","_TableStatusText":"__TableStatusText_10av7_61","tableStatusText":"__TableStatusText_10av7_61"};
export { css, digest };
  