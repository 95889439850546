
const digest = '7c5350aa0cf57068167b14102bbd8b2f66f322abce399f7b6618134c70952bbb';
const css = `._CalendarDatePicker_s9bjx_1 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-inline: 6px;
  border-radius: 6px;
  box-shadow: var(--shadow-dark-lg);
  background-color: var(--color-white);
  overflow: hidden;
}

.__CalendarDialogTitle_s9bjx_12 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 24px;
  background-color: var(--color-gray-200);
}

.__CalendarDialogTitleText_s9bjx_21 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.55;
}

.__CalendarDialogCloseButton_s9bjx_28 {
  appearance: none;
  border: 0;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  color: inherit;
}

.__CalendarDialogCloseButton_s9bjx_28:enabled {
  cursor: pointer;
}

.__CalendarDialogBody_s9bjx_43 {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: 0;
}

.__Nav_s9bjx_51 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid var(--color-gray-300);
}

.__TableWrapper_s9bjx_59 {
  padding: 0 12px 12px;
  overflow: auto;
}

.__TableHeader_s9bjx_64 {
  position: sticky;
  top: 0;
  padding: 12px 0 0;
  background-color: var(--color-white);
}

.__TableHeader_s9bjx_64::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 6px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.16), rgba(255, 255, 255, 0));
}

.__MonthHeader_s9bjx_81 {
  padding: 6px 12px;
  margin: 12px 0;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid var(--gray-300, #cbd5e0);
}

.__Table_s9bjx_59 + .__MonthTitle_s9bjx_89 {
  margin-top: 18px;
}

.__Table_s9bjx_59 {
  box-sizing: border-box;
  display: table;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
}

.__Thead_s9bjx_103 {
  display: table-header-group;
}

.__Tr_s9bjx_107 {
  display: table-row;
}

.__Table_s9bjx_59 :is(.__Th_s9bjx_103, .__Td_s9bjx_111) {
  display: table-cell;
  padding: 0;
  text-align: center;
  font-weight: 300;
}

.__Table_s9bjx_59 .__Th_s9bjx_103 span {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  margin-inline: auto;
}

.__Table_s9bjx_59 .__Th_s9bjx_103.-sunday {
  color: var(--color-red-600);
}

.__Table_s9bjx_59 .__Th_s9bjx_103.-saturday {
  color: var(--color-cyan-800);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"CalendarDatePicker":"_CalendarDatePicker_s9bjx_1","calendarDatePicker":"_CalendarDatePicker_s9bjx_1","_CalendarDialogTitle":"__CalendarDialogTitle_s9bjx_12","calendarDialogTitle":"__CalendarDialogTitle_s9bjx_12","_CalendarDialogTitleText":"__CalendarDialogTitleText_s9bjx_21","calendarDialogTitleText":"__CalendarDialogTitleText_s9bjx_21","_CalendarDialogCloseButton":"__CalendarDialogCloseButton_s9bjx_28","calendarDialogCloseButton":"__CalendarDialogCloseButton_s9bjx_28","_CalendarDialogBody":"__CalendarDialogBody_s9bjx_43","calendarDialogBody":"__CalendarDialogBody_s9bjx_43","_Nav":"__Nav_s9bjx_51","nav":"__Nav_s9bjx_51","_TableWrapper":"__TableWrapper_s9bjx_59","tableWrapper":"__TableWrapper_s9bjx_59","_TableHeader":"__TableHeader_s9bjx_64","tableHeader":"__TableHeader_s9bjx_64","_MonthHeader":"__MonthHeader_s9bjx_81","monthHeader":"__MonthHeader_s9bjx_81","_Table":"__Table_s9bjx_59","table":"__Table_s9bjx_59","_MonthTitle":"__MonthTitle_s9bjx_89","monthTitle":"__MonthTitle_s9bjx_89","_Thead":"__Thead_s9bjx_103","thead":"__Thead_s9bjx_103","_Tr":"__Tr_s9bjx_107","tr":"__Tr_s9bjx_107","_Th":"__Th_s9bjx_103","th":"__Th_s9bjx_103","_Td":"__Td_s9bjx_111","td":"__Td_s9bjx_111"};
export { css, digest };
  