import { DIALOG_OPEN_BUTTON_ID_PREFIX } from '../../shared/constants';
import { id, initialize, reRenderApp } from '../../components/RentalStart';
import type { Settings } from '../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../shared/apiFetchers/embedProductsFetcher';
import { injectRentalStart } from '../../components/RentalStart/shared/uses/useRentalStart';

export const rentalStartLoader = (
  key: string,
  {
    embedDomain,
    target,
    settings,
    product,
    isNoStyle,
  }: {
    embedDomain: string;
    target: Element;
    settings: Settings;
    product: Product;
    isNoStyle: boolean;
  }
) => {
  const placeholder = document.createElement('div');
  placeholder.setAttribute('id', id);
  target.appendChild(placeholder);
  initialize(key, { embedDomain, settings, product, isNoStyle });
};

export const rentalStartReLoader = (cacheKey: string, { target }: { target: Element }) => {
  const { getId } = injectRentalStart(cacheKey);
  if (document.querySelector('#' + getId(`${DIALOG_OPEN_BUTTON_ID_PREFIX}${cacheKey}`))) {
    // すでにレンタルボタンが存在していたら再描画しない
    return;
  }
  const placeholder = document.createElement('div');
  placeholder.setAttribute('id', id);
  target.appendChild(placeholder);
  reRenderApp(cacheKey);
};
