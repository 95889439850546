import { h } from '../../../../shared/utils/tsxToDom';
import styles from './index.module.css';

export const ButtonPrimary = (
  {
    ariaControls,
    ariaExpanded,
    id,
    style,
    type,
    iconVisible = true,
    disabled = false,
    isNoStyle = false,
    onClick,
  }: {
    ariaControls?: string;
    ariaExpanded?: string;
    id?: string;
    style?: string;
    type?: HTMLButtonElement['type'];
    iconVisible?: boolean;
    disabled?: boolean;
    isNoStyle?: boolean;
    onClick?: (event: Event) => void;
  },
  children: Element[]
) => {
  // isNoStyleがtrueの場合はボタン固有のスタイル反映は行わない
  // rentify-trigger-buttonを固定で指定するので、好きにスタイルを当ててもらう形
  return (
    <button
      class={`${isNoStyle ? '' : styles.ButtonPrimary + ' '}rentify-trigger-button`}
      style={isNoStyle ? '' : style}
      type={type}
      id={id}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      {iconVisible && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 2L4.59 3.41L9.17 8L4.59 12.59L6 14L12 8L6 2Z" fill="currentColor" />
        </svg>
      )}
    </button>
  );
};
