import { h, Fragment } from '../../../../shared/utils/tsxToDom';
import { injectRentalStart } from '../../shared/uses/useRentalStart';
import { closeAnimatedDialog, openAnimatedDialog } from '../../../shared/utils/dialogHandlers';
import { CalendarDatePickerOpener } from './PickerOpener';
import { CalendarDatePicker } from './Picker';
import { AppDialog } from '../../../shared/components/AppDialog';
import styles from './index.module.css';

export const CalendarDate = ({
  cacheKey,
  title,
  selectedDate,
  selectedFromDate,
  selectedToDate,
  ariaDescribedBy,
  onDateSelect,
}: {
  cacheKey: string;
  title: string;
  selectedDate: string;
  selectedFromDate?: string;
  selectedToDate?: string;
  ariaDescribedBy?: string;
  onDateSelect: (selectedDate: string) => void;
}) => {
  const { getId } = injectRentalStart(cacheKey);

  let calendarDialogOpener: any = null;
  let calendarDialog: any = null;

  return (
    <>
      {
        (calendarDialogOpener = (
          <CalendarDatePickerOpener
            id={getId('calendar-dialog-open-button')}
            ariaDescribedBy={ariaDescribedBy}
            ariaControls={getId('calendar-dialog')}
            selectedDate={selectedDate}
            onClick={() => {
              openAnimatedDialog(calendarDialog, calendarDialogOpener);
            }}
          />
        ))
      }
      {
        (calendarDialog = (
          <AppDialog
            id={getId('calendar-dialog')}
            class={styles._CalendarDialog}
            onCancel={() => closeAnimatedDialog(calendarDialog, calendarDialogOpener)}
          >
            <CalendarDatePicker
              cacheKey={cacheKey}
              title={title}
              selectedDate={selectedDate}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              onDateSelect={(newSelectedDate) => {
                closeAnimatedDialog(calendarDialog, calendarDialogOpener, () =>
                  onDateSelect(newSelectedDate)
                );
              }}
              onDialogClose={() => {
                closeAnimatedDialog(calendarDialog, calendarDialogOpener);
              }}
            />
          </AppDialog>
        ))
      }
    </>
  );
};
