import type { Settings } from '../../../../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../../../../shared/apiFetchers/embedProductsFetcher';
import type { RuntimeIdentifier } from '../../../../../shared/utils/getRuntimeIdentifier';

export type RentalStartCtx = ReturnType<typeof useRentalStart>;

const cacheMap = new Map<string, RentalStartCtx>();

export const provideRentalStart = (cacheKey: string, v: RentalStartCtx): RentalStartCtx => {
  if (cacheMap.has(cacheKey)) {
    throw new Error('RentalStart is already provided');
  }
  cacheMap.set(cacheKey, v);
  return v;
};

export const injectRentalStart = (cacheKey: string): RentalStartCtx => {
  const cache = cacheMap.get(cacheKey);
  if (!cache) {
    throw new Error('RentalStart is not provided');
  }
  return cache;
};

export const useRentalStart = (
  cacheKey: string,
  {
    embedDomain,
    runtimeIdentifier,
    settings,
    product,
    isNoStyle,
  }: {
    embedDomain: string;
    runtimeIdentifier: RuntimeIdentifier;
    settings: Settings;
    product: Product;
    isNoStyle: boolean;
  }
) => {
  if (cacheMap.has(cacheKey)) {
    throw new Error('RentalStart is already provided');
  }

  const state = {
    rentalDialogVisible: false,
  };

  const handler = {
    rentalDialogRender: () => {},
  };

  return {
    get cacheKey() {
      return cacheKey;
    },
    get embedDomain() {
      return embedDomain;
    },
    get settings() {
      return settings || {};
    },
    get isNoStyle() {
      return isNoStyle || false;
    },

    get product() {
      return product;
    },

    get rentalDialogVisible() {
      return state.rentalDialogVisible;
    },

    getId(key: string) {
      return runtimeIdentifier.getId(key);
    },

    setRentalDialogRender(v: () => void) {
      handler.rentalDialogRender = v;
    },

    openRentalDialog() {
      state.rentalDialogVisible = true;
      handler.rentalDialogRender();
    },

    closeRentalDialog() {
      state.rentalDialogVisible = false;
    },
  };
};
