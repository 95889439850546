import { h } from '../../shared/utils/tsxToDom';
import { getRuntimeIdentifier } from '../../shared/utils/getRuntimeIdentifier';
import { domReplacer } from '../../shared/utils/tsxToDom/domReplacer';
import {
  provideRentalStart,
  useRentalStart,
  injectRentalStart,
} from './shared/uses/useRentalStart';
import { App } from './App';
import type { Settings } from '../../shared/apiFetchers/embedButtonSettingsFetcher';
import type { Product } from '../../shared/apiFetchers/embedProductsFetcher';

const runtimeIdentifier = getRuntimeIdentifier();
export const id = runtimeIdentifier.getId('rental-start');

export const initialize = (
  key: string,
  {
    embedDomain,
    settings,
    product,
    isNoStyle,
  }: {
    embedDomain: string;
    settings: Settings;
    product: Product;
    isNoStyle: boolean;
  }
) => {
  const rentalStartCtx = provideRentalStart(
    key,
    useRentalStart(key, { embedDomain, runtimeIdentifier, settings, product, isNoStyle })
  );
  domReplacer(() => <App rentalStartCtx={rentalStartCtx} />, document.querySelector('#' + id));
};

export const reRenderApp = (key: string) => {
  // キャッシュからContextを取得
  const rentalStartCtx = injectRentalStart(key);
  domReplacer(() => <App rentalStartCtx={rentalStartCtx} />, document.querySelector('#' + id));
};
