import { embedButtonSettingsFetcher } from '../shared/apiFetchers/embedButtonSettingsFetcher';
import { embedProductsFetcher } from '../shared/apiFetchers/embedProductsFetcher';
import { rentalStartLoader, rentalStartReLoader } from './componentLoaders/rentalStartLoader';

export const widgetLoader = async () => {
  const embedDomain = document.currentScript!.dataset.rentifyEmbedDomain || window.location.host;
  const targets = document.querySelectorAll('[data-rentify-button]');
  if (targets.length === 0) {
    console.error('レンタルボタンの埋め込み先要素が見つかりませんでした。');
    return;
  }

  const buttonSettingsRes = await embedButtonSettingsFetcher({ embedDomain });
  if (!buttonSettingsRes.ok) {
    console.error(buttonSettingsRes.error.message);
    return;
  }
  const { settings } = buttonSettingsRes.data;

  let index = 0;
  for (const target of targets) {
    const sku = target.getAttribute('data-rentify-sku');
    if (!sku) {
      console.error('skuを取得できませんでした。');
      continue;
    }

    const productsRes = await embedProductsFetcher({ embedDomain, slug: sku });
    if (!productsRes.ok) {
      console.error(productsRes.error.message);
      continue;
    }
    const { product } = productsRes.data;

    const isNoStyle = target.hasAttribute('data-rentity-button-no-style');

    const skuWithIndex = `${index}_${sku}`; // ボタン要素のidの値の一部やuseRentalStartのcacheKeyなどで使用される
    rentalStartLoader(skuWithIndex, { embedDomain, target, settings, product, isNoStyle });
    index++;
  }
};

export const widgetReLoader = () => {
  const targets = document.querySelectorAll('[data-rentify-button]');
  if (targets.length === 0) {
    return;
  }

  let index = 0;
  for (const target of targets) {
    const sku = target.getAttribute('data-rentify-sku');
    if (!sku) {
      console.error('skuを取得できませんでした。');
      continue;
    }
    const skuWithIndex = `${index}_${sku}`; // ボタン要素のidの値の一部やuseRentalStartのcacheKeyなどで使用される
    rentalStartReLoader(skuWithIndex, { target });
    index++;
  }
};
